import React from 'react'
import Layout from '../../components/Layout'
import Header from '../../components/Header'
import Particles from 'react-tsparticles'
import config from '../../assets/lib/particles-recruit.json'

const DesignerPageContent = () => (
  <div class="main">
    <section class="section">
      <div class="section__inner section__inner--recruit">
      <ul class="recruit-content">
        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">募集要項</h2>
          </div>
          <div class="recruit-content__table">
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">職種</div>
              <div class="recruit-content__cell">デザイナー</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務場所</div>
              <div class="recruit-content__cell">遠隔</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">勤務形態</div>
              <div class="recruit-content__cell">週15時間以上</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">契約形態</div>
              <div class="recruit-content__cell">雇用契約（正社員/アルバイト）または業務委託契約</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">給与</div>
              <div class="recruit-content__cell">月給20万〜80万（正社員）、時給1,000円〜6000円（アルバイト/業務委託契約）</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">必要なスキル等</div>
              <div class="recruit-content__cell">後述</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">試用期間</div>
              <div class="recruit-content__cell">3ヶ月</div>
            </div>
            <div class="recruit-content__table-row">
              <div class="recruit-content__header-cell">特記事項</div>
              <div class="recruit-content__cell">保険完備、副業可</div>
            </div>
          </div>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">業務内容</h2>
          </div>
          <h3 class="recruit-content__title-sub">新規WebサービスのUX/UI設計</h3>
          <ul class="recruit-content__body">
            <li><a class="recruit-content__link" href="http://commet-pm-teaser-ja.strikingly.com/">作り途中のティーザーサイト</a> ← こちらの修正からお願いしたいです</li>
          </ul>
          <h3 class="recruit-content__title-sub">自社WebサービスのUIデザイン・マークアップ</h3>
          <ul class="recruit-content__body">
            <li><a class="recruit-content__link" href="https://commet.cc/">Commet</a> のUIの改善</li>
          </ul>
          <h3 class="recruit-content__title-sub">お客様のシステム・webサービスの受託開発のデザイン・マークアップ</h3>
          <ul class="recruit-content__body">
            <li><a class="recruit-content__link" href="/works/">開発実績</a>もご参照下さい（webサービスが多いです）</li>
          </ul>
          <h3 class="recruit-content__title-sub">弊社webサイト・blogサイトの改善</h3>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">必須の条件</h2>
          </div>
          <h3 class="recruit-content__title-sub">ソフトウェア開発、あるいは弊社の事業なり弊社自体に興味・熱意があること</h3>
          <ul class="recruit-content__body">
            <li>仕事で一番必要なのは、興味だったり意欲だと思うので</li>
          </ul>
          <h3 class="recruit-content__title-sub">弊社の事業なり弊社自体に興味があること</h3>
          <h3 class="recruit-content__title-sub">以下のいずれか</h3>
          <ul class="recruit-content__body">
            <li>デザイン実務経験1年以上（webサービスであれば尚可）</li>
            <li>デザイン系の専門学校卒業</li>
          </ul>
        </li>

        <li class="recruit-content__list-item">
          <div class="recruit-content__title">
            <h2 class="recruit-content__title-txt">知ってると望ましい知識・技術</h2>
          </div>
          <p class="recruit-content__text">必須の条件以外に、以下の知識・技術があると望ましいです。特にプラスとなるものには★をつけてあります。</p>
          <h3 class="recruit-content__title-sub">★開発者との共同作業の知識・経験</h3>
          <ul class="recruit-content__body">
            <li>Git, GitHub</li>
          </ul>
          <h3 class="recruit-content__title-sub">★webサービスの運営経験（改善、テスト等）</h3>
          <h3 class="recruit-content__title-sub">フロントエンド周りの知識（JavaScript）</h3>
          <h3 class="recruit-content__title-sub">英語の読み書き（海外の開発者とのやりとりが発生する可能性があるので）</h3>
          <h3 class="recruit-content__title-sub">webマーケティング</h3>
        </li>
      </ul>

      </div>
    </section>
    <section class="section section--gray">
      <div class="recruit-content__buttons">
        <h3 class="recruit-content__buttons-annotation">「話を聞いてみたい」程度の方も含め、ご興味のある方はまずはご連絡ください。</h3>
        <a href="https://blog.mobalab.net/jobs/job-application-form/" className="btn-blue">採用応募フォーム</a>
        <a href="/recruit/" class="btn-back-to-recruit-list">採用情報一覧に戻る</a>
      </div>
    </section>
  </div>
)

const DesignerPage = (props) => (
  <Layout
    title="採用情報・デザイナー（フルリモート） | 株式会社もばらぶ"
    description="株式会社もばらぶでは、現在フルリモートでのデザイナーを募集しています。ご興味のある方はお気軽にご応募ください。"
    pathName={props.location.pathname}
  >
    <Header />
    <div class="hero hero--job">
      <Particles className="hero__background hero__background--job" params={config}/>
      <div class="hero__title-area">
        <p class="hero__title-ja hero__title-ja--job">採用情報</p>
        <h1 class="hero__title hero__title--job">デザイナー</h1>
      </div>
    </div>
    <DesignerPageContent />
  </Layout>
)
export default DesignerPage